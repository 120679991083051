import React, { useEffect } from "react";
import './App.css';
import Navbar from './components/Navbar.js'
import Intro from './components/Intro.js'
import About from './components/About.js'
import Resume from './components/Resume.js'
import './components/PortfolioDetails.css'
import Projects from "./components/Projects";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import 'bootstrap/dist/css/bootstrap.min.css';
import AOS from "aos";
import "aos/dist/aos.css";
import { BiUpArrowAlt } from "react-icons/bi";

function App() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  /**
   * Easy selector helper function
   */
  const select = (el, all = false) => {
    el = el.trim()
    if (all) {
      return [...document.querySelectorAll(el)]
    } else {
      return document.querySelector(el)
    }
  }

  /**
   * Easy event listener function
   */
  const on = (type, el, listener, all = false) => {
    let selectEl = select(el, all)
    if (selectEl) {
      if (all) {
        selectEl.forEach(e => e.addEventListener(type, listener))
      } else {
        selectEl.addEventListener(type, listener)
      }
    }
  }

  /**
   * Easy on scroll event listener 
   */
  const onscroll = (el, listener) => {
    el.addEventListener('scroll', listener)
  }

  /**
   * Back to top button
   */
  let backtotop = select('.back-to-top')
  if (backtotop) {
    const toggleBacktotop = () => {
      if (window.scrollY > 100) {
        backtotop.classList.add('active')
      } else {
        backtotop.classList.remove('active')
      }
    }
    window.addEventListener('load', toggleBacktotop)
    onscroll(document, toggleBacktotop)
  }

  /**
   * Scrolls to an element with header offset
   */
  const scrollto = (el) => {
    let elementPos = select(el).offsetTop
    window.scrollTo({
      top: elementPos,
      behavior: 'smooth'
    })
  }

  return (
    <div className="App">
      <div className="main">
        <Navbar 
          select={select} 
          on={on} 
          onscroll={onscroll} 
          scrollto={scrollto} 
        />
        <Intro/>

        <div className="mainContent">
          <About/>
          <Resume/>
          <Projects/>
          <Contact/>
        </div>
      </div>

      <Footer/>
      <a href="#hero" class="back-to-top d-flex scrollto"><i class="bi bi-arrow-up-short"><BiUpArrowAlt/></i></a>
    </div>
  );
}

export default App;
