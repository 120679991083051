import React from 'react';
import './Projects.css';
import { ProjectsData } from './ProjectsData';
// import { BiArch, BiFile, BiLayer, BiLogoDribbble, BiSlideshow, BiTachometer } from 'react-icons/bi';

const Projects = (props) => {
  const first_box_classname = "col-lg-4 col-md-6 d-flex align-items-stretch";
  const second_box_classname = "col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0";
  const third_box_classname = "col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0";
  const fourth_box_classname = "col-lg-4 col-md-6 d-flex align-items-stretch mt-4";

  const getItemClassName = (index) => {
    if (index === 0) {
      return first_box_classname;
    } else if (index === 1) {
      return second_box_classname;
    } else if (index === 2) {
      return third_box_classname;
    } 
    return fourth_box_classname;
  }

  const getAOSDelay = (index) => {
    if (index%3 === 0) {
      return "100";
    } else if (index%3 === 1) {
      return "200";
    }
    return "300";
  }

  return (
    <section id="projects" class="projects">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h2>Projects</h2>
        </div>

        <div class="row">
          {ProjectsData.map((item, index) => {
            return (
              <div key={index} className={getItemClassName(index)} data-aos="zoom-in" data-aos-delay={getAOSDelay(index)}>
                <div class={`icon-box iconbox-${item.colour}`}>
                  <div class="icon">
                    <svg width="100" height="100" viewBox="0 0 600 600" xmlns="http://www.w3.org/2000/svg">
                      <path className="orange_blob" stroke="none" strokeWidth="0" fill="#f5f5f5" d={item.path_d}></path>
                    </svg>
                    {/* {item.icon} */}
                  </div>
                  <h4><a href={item.link}> {item.title} </a></h4>
                  <p>{item.description}</p>
                </div>
              </div>
            );
          })}
        </div>

      </div>
    </section>
  );
}

export default Projects;
