import React from 'react';
import './Resume.css';

const Resume = (props) => {
  return (
    <section id="resume" class="resume">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h2>Resume</h2>
        </div>

        <div class="row">
          <div class="resume-column col-lg-10">
            <h3 class="resume-title">Professional Experience</h3>
            <div class="resume-item">
              <h4>Software Engineering Intern, Full-Stack Development</h4>
              <h5>May 2024 - Aug 2024</h5>
              <p>Espresso Capital | <em>Toronto, ON, Canada</em></p>
              <ul>              
                <li>Reduced due diligence submission time from 8 to 2 weeks through an agentic (AI) system.</li>
                <li>Achieved 15% time-savings per month for each of 50+ borrowers by offering an Excel-based solution to the financial ingestion process, eliminating the tedious and error-prone process of manual inputs.</li>
                <li>Streamlined data synchronization, eliminating 4-week delay through an automated event-driven system.</li>
              </ul>
            </div>
            <div class="resume-item">
              <h4>Software Engineering Intern, Full-Stack Development</h4>
              <h5>Jan 2024 - April 2024</h5>
              <p>TD Bank | <em>Toronto, ON, Canada</em></p>
              <ul>              
                <li>Leveraged Agile development practices to implement 5 key features, enabling users to designate multi-holding account beneficiaries & successors, accelerating product release schedule by 8 weeks.</li>
                <li>Collaborated with Quality Assurance to test each user story; resolved all identified bugs within 72 hours.</li>
              </ul>
            </div>
            <div class="resume-item">
              <h4>Software Engineering Intern, Low Power Embedded Artificial Intelligence</h4>
              <h5>May 2023 - Aug 2023</h5>
              <p>Qualcomm | <em>Markham, ON, Canada</em></p>
              <ul>              
                <li>Implemented an individual project to detect hardware inefficiencies by generating and profiling low-level events; Presented 3 key findings to 70 members, increasing knowledge of various optimizations.</li>
                <li>Deployed an internal tool used during pre-silicon to capture configuration field mismatches within binary files, successfully resolving 20+ field discrepancies across 950 test cases.</li>
              </ul>
            </div>
            <div class="resume-item">
              <h4>Software Engineering Intern, Machine Learning Systems</h4>
              <h5>May 2022 - Sept 2022</h5>
              <p>Qualcomm | <em>Markham, ON, Canada</em></p>
              <ul>
                <li>Developed a supervised learning algorithm from scratch to explore machine learning training optimization for embedded systems, achieving 98.6% post-quantization inferencing accuracy. </li>
                <li>Presented computer-vision based algorithms to 2 VPs regarding incoming machine learning regulations, resulting in an increased annual investment of $500,000+ to understand deep learning model predictions. </li>
              </ul>
            </div>
            <div class="resume-item">
              <h4>Software Engineering Intern, Frontend Development</h4>
              <h5>Mar 2021 - Aug 2021</h5>
              <p>Tauria | <em>Waterloo, ON, Canada</em></p>
              <ul>
                <li>Independently engineered a data-stream organizing service to production, granting clients the ability to reduce their CPU consumption by at least 15% through a custom range selector and data stream filtration.</li>
                <li>Spearheaded inter-team communication by leading 10 weekly sprints, prioritizing the client’s need for our military grade end-to-end edge encryption video conferencing platform.</li>
                <li>Collaborated in a startup environment with partners from South Korea, Brazil, and the United States to optimize frontend architecture; Improving UX by 20% through the single-responsibility principle.</li>
              </ul>
            </div>
          </div>
        </div>

      </div>
    </section>
  );
}

export default Resume;
